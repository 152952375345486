// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  GithubAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  OAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  updateProfile,
  verifyPasswordResetCode,
  confirmPasswordReset,
  applyActionCode,
  signOut,
  signInWithCredential,
  updateEmail,
  connectAuthEmulator,
} from "firebase/auth";

import {
  getFirestore,
  collection,
  doc,
  setDoc,
  getDoc,
  query,
  where,
  getDocs,
  addDoc,
  serverTimestamp,
  connectFirestoreEmulator,
} from "firebase/firestore";

import { getStorage, ref, uploadBytes } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBBExs0_7S7lX3Q5Hdo6jNp7DDZVWtvrlU",
  authDomain: "auth.enhancetube.com",
  databaseURL:
    "https://enhtube-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "enhtube",
  storageBucket: "enhtube.appspot.com",
  messagingSenderId: "351908861938",
  appId: "1:351908861938:web:847720efb8c6220619bd6b",
  measurementId: "G-1WZ4CZWCPG",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

if (process.env.NODE_ENV === "development") {
  connectFirestoreEmulator(db, "127.0.0.1", 8080);
  connectAuthEmulator(auth, "http://127.0.0.1:9099");
}

onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    const uid = user.uid;

    // ...
  } else {
    // User is signed out
  }
});

export {
  auth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  GithubAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  OAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  updateProfile,
  confirmPasswordReset,
  verifyPasswordResetCode,
  applyActionCode,
  signOut,
  signInWithCredential,
  db,
  collection,
  doc,
  setDoc,
  getDoc,
  query,
  where,
  getDocs,
  addDoc,
  serverTimestamp,
  uploadBytes,
  updateEmail,
  getStorage,
  ref,
};
